import {Component} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Adventskalender';
  year = (new Date()).getFullYear();
  siteurl = location.href.split('?')[0];
  // Ladda upp till någon existerade cache
  bannerUrls = {
    2020: 'https://s3.amazonaws.com/gs-geo-images/283eb6bd-7e08-467f-b6b0-b6d3860b2aab.jpg',
    2021: 'https://s3.amazonaws.com/gs-geo-images/e7762868-493d-4c10-963f-25a3273d7eda.jpg',
    2022: 'https://s3.amazonaws.com/gs-geo-images/48c1e12d-25a8-4af5-8d46-92e1612b2334.jpg'
  };
  // Just always use 2022s banner for simplicity
  bannerUrl = 'https://s3.amazonaws.com/gs-geo-images/48c1e12d-25a8-4af5-8d46-92e1612b2334.jpg';
  backgroundUrl = this.siteurl + 'assets/images/background_' + this.year + '.jpg';
  tinymceContent = '';
  cacheDescription = '<div><img src="' + this.bannerUrl + '" alt="' + this.title + ' ' + this.year + '"></div>';
  adventValue = '1'; // The value is a string

  set htmlOutput(data) {
  }

  get htmlOutput() {
    return this.cacheDescription + '\n' + this.tinymceContent;
  }

  set reviewerNote(data) {
  }

  get reviewerNote() {
    let text: string;
    text = 'Hej Reviewer!\n\nDen här cachen är en del av en Adventskalenderserie i Umeå som vi planerar att lägga ut.\n';
    text += 'Vi skulle vara otroligt tacksamma om varje cache släpps på sin advent.\n\n';
    if (this.adventValue === '1') {
      text += 'Alltså för den här cachen kl. 10.00 den ' + this.advent()[0] + '.\n';
    } else if (this.adventValue === '2') {
      text += 'Alltså för den här cachen kl. 10.00 den ' + this.advent()[1] + '.\n';
    } else if (this.adventValue === '3') {
      text += 'Alltså för den här cachen kl. 10.00 den ' + this.advent()[2] + '.\n';

    } else if (this.adventValue === '4') {
      text += 'Alltså för den här cachen kl. 10.00 den ' + this.advent()[3] + '.\n';

    }
    text += '\nMed vänliga hälsningar\nCO';
    return text;
  }

  advent() {
    let first;
    let second;
    let third;
    let fourth;
    if (this.year === 2023) {
      first = '3:e december';
      second = '10:e december';
      third = '17:e december';
      fourth = '24:a december';
    } else if (this.year === 2024) {
      first = '1:a december';
      second = '8:e december';
      third = '15:e december';
      fourth = '22:e december';
    } else if (this.year === 2025) {
      first = '30:e november';
      second = '7:e december';
      third = '14:e december';
      fourth = '21:e december';
    } else if (this.year === 2026) {
      first = '29:e november';
      second = '6:e december';
      third = '13:e december';
      fourth = '20:e december';
    } else if (this.year === 2027) {
      first = '28:e november';
      second = '5:e december';
      third = '12:e december';
      fourth = '19:e december';
    } else if (this.year === 2028) {
      first = '3:e november';
      second = '10:e december';
      third = '17:e december';
      fourth = '24:e december';
    }
    return [first, second, third, fourth];
  }
}
